class CreateProductController {
  constructor(ProductsModel, FlashService, lessonPlans) {
    this.ProductsModel = ProductsModel;
    this.FlashService = FlashService;
    this.submitting = false;
    this.product = {
      sessiontypes: [],
      coursetypes: [],
      categories: [],
    };
    this.displayLessonPlanType = this.displayLessonPlanType.bind(this);
    this.lessonPlans = lessonPlans;
    this.createProduct = this.createProduct.bind(this);
  }

  displayLessonPlanType(type) {
    if (type === "course_type") {
      return "Course Type";
    }
    if (type === "session_type") {
      return "Session Type";
    }
    if (type === "permit") {
      return "Permit";
    }
    return "";
  }

  addLessonPlan() {
    this.product.lesson_plan = this.lessonPlan;
  }

  removeLessonPlan() {
    delete this.product.lesson_plan;
  }

  createProduct() {
    this.submitting = true;
    let product = {
      product_name: this.product.name,
      product_price: this.product.price * 100,
      product_sku: this.product.sku,
      session_types: this.product.sessiontypes,
      course_types: this.product.coursetypes,
      product_description: this.product.description,
      categories: this.product.categories,
      product_sequence: this.product.sequence,
      purchase_instructions: this.product.purchase_instructions,
      allow_partial_payment: this.product.allow_partial_payment ? "1" : "0",
      allows_drive_scheduling: "0",
      deposit_required: this.product.deposit_required ? "1" : "0",
      deposit_type: this.product.deposit_type,
      deposit_amount: this.product.deposit_amount,
      minimum_payment_amount: this.product.minimum_payment_amount,
    };
    if (this.product.lesson_plan && this.product.lesson_plan !== null) {
      product.lesson_plan = {
        id: this.product.lesson_plan.id,
      };
    }
    this.ProductsModel.create(product)
      .then((response) => {
        this.submitting = false;
        this.FlashService.setMessage({
          type: "success",
          message: response.message,
        });
        this.product = {
          sessiontypes: [],
        };
      })
      .catch((response) => {
        this.submitting = false;
      });
  }
}
CreateProductController.$inject = [
  "ProductsModel",
  "FlashService",
  "lessonPlans",
];
export default CreateProductController;
