"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var states_1 = require("../../../../../common/constants/states");
var time_1 = require("../../../../../common/helpers/time");
var saveToDisk_1 = require("../../../../../common/helpers/saveToDisk");
var AdminStudentEditPermitController = /** @class */ (function () {
    function AdminStudentEditPermitController($state, $stateParams, UsersModel, FlashService, PermitModel, permitData, FilesModel, AuthService, APPURL) {
        this.initialLoad = true;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.states = states_1.States;
        this.UsersModel = UsersModel;
        this.PermitModel = PermitModel;
        this.AuthService = AuthService;
        this.FlashService = FlashService;
        this.submitted = false;
        this.stateRequiresPermit = false;
        this.FilesModel = FilesModel;
        this.files = permitData.files;
        this.handleFileUpload = this.handleFileUpload.bind(this);
        this.permit = {
            'number': '',
            'issue_date': '',
            'expiration_date': ''
        };
        if (permitData.permit.number) {
            this.permit.number = permitData.permit.number;
        }
        if (permitData.permit.issuedate) {
            this.permit.issue_date = (0, time_1.dateStringToDate)(permitData.permit.issuedate);
        }
        if (permitData.permit.expirationdate) {
            this.permit.expiration_date = (0, time_1.dateStringToDate)(permitData.permit.expirationdate);
        }
        this.updateIssueDate = this.updateIssueDate.bind(this);
        this.updateExpirationDate = this.updateExpirationDate.bind(this);
    }
    AdminStudentEditPermitController.prototype.formatDate = function (datestring) {
        return new Date(datestring).toLocaleDateString("en-US", {
            day: "2-digit", "month": "2-digit", "year": "numeric"
        });
    };
    AdminStudentEditPermitController.prototype.deleteFile = function (id) {
        var _this = this;
        this.FilesModel.destroy(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.files = _this.files.filter(function (file) {
                return file.id !== id;
            });
        }).catch(function (Error) {
        });
    };
    AdminStudentEditPermitController.prototype.downloadFile = function (id) {
        var _this = this;
        this.FilesModel.get(id).then(function (response) {
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            (0, saveToDisk_1.saveToDisk)(response.temporary_url);
        }).catch(function (Error) {
        });
    };
    AdminStudentEditPermitController.prototype.handleFileUpload = function (formData) {
        var _this = this;
        formData.append("students", [this.$stateParams.userId]);
        this.FilesModel.uploadFile("permit", "permit", formData).then(function (response) {
            _this.files.push(response.file);
        }).catch(function (Error) {
        });
    };
    AdminStudentEditPermitController.prototype.updateIssueDate = function (newDate) {
        this.permit.issue_date = newDate.selected[0];
    };
    AdminStudentEditPermitController.prototype.updateExpirationDate = function (newDate) {
        this.permit.expiration_date = newDate.selected[0];
    };
    AdminStudentEditPermitController.prototype.updatePermit = function () {
        var _this = this;
        this.submitted = true;
        var permit = Object.assign({}, this.permit);
        this.PermitModel.update(this.$stateParams.userId, permit).then(function (response) {
            _this.submitted = false;
            _this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            _this.$state.go('drivecoach.admin.students.view', { 'userId': _this.$stateParams.userId }, { reload: true });
        }).catch(function (error) {
            _this.submitted = false;
        });
    };
    return AdminStudentEditPermitController;
}());
AdminStudentEditPermitController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'PermitModel', 'permitData', 'FilesModel', 'AuthService', 'APPURL'];
exports.default = AdminStudentEditPermitController;
