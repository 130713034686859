import moment from 'moment'
import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'
class AdminInstructorsEditAvailabilityController {

    constructor($state, $stateParams, UsersModel, FlashService, UsermetaModel) {
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.UsermetaModel = UsermetaModel;
        this.busy = false;
        this.availability = {
            'monday' : [],
            'tuesday' : [],
            'wednesday' : [],
            'thursday' : [],
            'friday' : [],
            'saturday' : [],
            'sunday' : []
        };
      
        this.updateAvailability = this.updateAvailability.bind(this)

        this.today = moment().format( 'M/D/YYYY' );

        UsersModel.getInstructorByID( $stateParams.instructorId ).then( response => {
            this.instructor = response;
            forEach(this.instructor.usermeta.availability, ( range, day ) => {
                forEach(range, ( values, index ) => {
                    range[index].from = moment( this.today + ' ' + values.from, "M/D/YYYY Hms" ).toDate();
                    range[index].to = moment( this.today + ' ' + values.to, "M/D/YYYY Hms" ).toDate();
                } );
                this.availability[day] = range;
            } );
        } );
    }

    editInstructorAvailability() {
        const availability = {};
        this.busy = true;
        forEach(cloneDeep(this.availability), (range, day) => {
            forEach(range, (values, index) => {
                range[index].from = moment(values.from).format( 'HH:mm' );
                range[index].to = moment(values.to).format( 'HH:mm' );
            } );
            availability[day] = range;
        });

        this.UsersModel.updateInstructorAvailability( this.instructor.id, availability ).then( response => {
            this.FlashService.setMessage( {
                'type' : 'success',
                'message' : response.message
            } );
            this.busy = false;
            this.$state.go( 'drivecoach.admin.instructors.view', { 'instructorId' : response.usermeta.user_id } );
        } ).catch( error => {
            this.busy = false;
        });
    }

    addSlot( day ) {
        this.availability[day].push( {
            'from' : moment().hours(8).minutes(0).seconds(0).milliseconds(0).toDate(),
            'to' : moment().hours(17).minutes(0).seconds(0).milliseconds(0).toDate()
        } );
    }

    removeSlot( day, index ) {
        this.availability[day].splice( index, 1 );
    }
  
    updateAvailability(data) {
      let day_of_week = data.name.split('_')[0]
      let from_or_to = data.name.split('_')[1]
      
      this.availability[day_of_week][data.index][from_or_to] = data.selected[0]
    }

}
AdminInstructorsEditAvailabilityController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', 'UsermetaModel'];
export default AdminInstructorsEditAvailabilityController;
