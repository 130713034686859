import { States } from '../../../common/constants/states'
import { PaymentMethods } from "../../../common/constants/payment_methods";
import { Months, Years } from "../../../common/constants/months_and_years";
import { createBillingGatewayFromPolicies } from '../../../classes/billing/BillingGateway';
import { inDollars } from '../../../../DriveScout/Billing/Teller';

class dsOrderFormController {

    constructor(UsersModel, FlashService, CreateOrderService) {
        this.loading = true;
        this.submitting = false;
        this.inDollars = inDollars.bind(this);
        this.coupons = [];
        this.CreateOrderService = CreateOrderService;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.billingAddressDoesNotAlreadyExist = false;

        this.surchargeAmountBilled = 0;

        /**
         * The coupon the admin selects to add to the order.
         */
        this.selectedCoupon = {};

        //////////////////////////////////

        this.$cashier = this.CreateOrderService.cashier();

        const policies = this.$cashier.policies();
        const billingPolicy = policies.getPolicyByName('billing');
        if (billingPolicy.metaExistsByKey('billing_gateway')) {
            this.gateway = billingPolicy.getMetaValueByKey('billing_gateway');
            this.gateway_config = createBillingGatewayFromPolicies(policies).config();
        } else {
            this.gateway = 'none';
            this.gateway_config = {};
        }

        // set surcharge data
        this.surchargeBilledSeparately = this.$cashier.surchargeBilledSeparately();
        this.surchargeAmount = this.$cashier.surchargeAmount();
        this.surchargeDetail = this.$cashier.surchargeDetail();

        // set payment methods
        this.payment_methods = Object.assign({}, PaymentMethods);
        this.months = Months;
        this.years = Years;
        this.states = States;

        this.loading = false;
    }

    removeCoupon(){
        this.$cashier.removeCoupon();
        this.$cashier.order().payments()[0].setAmount(this.$cashier.totalDue());
        this.$cashier.calculateTotal();
    }

    removeProductFromOrder(product) {
        this.$cashier.removeProduct(product);

        let oldPaymentAmount = this.$cashier.order().payments()[0].amount()
        let newTotalWithoutSurcharge = this.$cashier.totalDue()
        if(oldPaymentAmount > newTotalWithoutSurcharge) {
            this.$cashier.order().payments()[0].setAmount(newTotalWithoutSurcharge);
        }

        this.$cashier.calculateTotal();
    }

    /**
     * Set the billing address of the given payment at the index provided.
     * @param {Number} index 
     * @param {} address 
     */
    addBillingAddress(index, address) {
        this.$cashier.addBillingAddress(index, address);
        this.$cashier.calculateTotal();
    }
}
dsOrderFormController.$inject = ['UsersModel', 'FlashService', 'CreateOrderService'];
export default dsOrderFormController;
