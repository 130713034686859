import angular from 'angular'
import AdminInstructorsController from './admin-instructors.controller'
import template from './admin-instructors.html'
import AdminDashboardInstructorsIndex from './index/admin-instructors-index.module'
import AdminDashboardInstructorsView from './view/admin-view-instructor.module'
import AdminDashboardInstructorsEdit from './edit/admin-edit-instructor.module'
import AdminDashboardInstructorsFutureSessions from './future-sessions/admin-instructor-future-sessions.module'
import AdminDashboardInstructorLocations from './locations/admin-instructor-locations.module'
import AdminDashboardInstructorTasks from './tasks/admin-instructor-tasks.module'
import AdminDashboardInstructorAddLocation from './add-location/admin-instructor-add-location.module'
import AdminDashboardInstructorEditLocation from './edit-location/admin-edit-instructor-location.module'
import AdminDashboardInstructorEditLocations from './edit-locations/admin-edit-instructor-locations.module'
import AdminInstructorEmails from './emails/emails.module'
import AdminDashboardCreateInstructor from './create/admin-create-instructor.module'
import AdminDashboardTimeOffRequests from './time-off-requests/admin-view-time-off-requests.module'


function config($stateProvider, $urlRouterProvider, USER_ROLES) {
    $stateProvider
        .state('drivecoach.admin.instructors', {
            'url': '/instructors',
            'abstract': true,
            'controller': AdminInstructorsController,
            'controllerAs': 'vm',
            'template': template,
            data: {
                authorizedRoles: [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        });
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];


export default angular.module('admin.dashboard.instructors', [
    AdminDashboardInstructorsIndex.name,
    AdminDashboardInstructorsView.name,
    AdminDashboardInstructorsEdit.name,
    AdminDashboardInstructorsFutureSessions.name,
    AdminDashboardInstructorLocations.name,
    AdminDashboardInstructorTasks.name,
    AdminDashboardInstructorAddLocation.name,
    AdminDashboardInstructorEditLocation.name,
    AdminDashboardInstructorEditLocations.name,
    AdminDashboardCreateInstructor.name,
    AdminInstructorEmails.name,
    AdminDashboardTimeOffRequests.name
]).config(config)
    .controller( 'AdminInstructorsController', AdminInstructorsController )
;
