class EditProductController {
    constructor(product, lessonPlans, FlashService, ProductsModel, $state) {
        this.FlashService = FlashService;
        this.ProductsModel = ProductsModel;
        this.product = product;
        this.$state = $state;
        this.lessonPlans = lessonPlans;
        this.displayLessonPlanType = this.displayLessonPlanType.bind(this);

        this.lessonPlans.forEach(plan => {
            if (product.lesson_plan && product.lesson_plan.id === plan.id) {
                this.product.lesson_plan = plan;
            }
        })
        this.editProduct = this.editProduct.bind(this);
    }

    displayLessonPlanType(type){
       if(type === 'course_type'){
           return 'Course Type';
       } 
       if (type === 'session_type'){
           return 'Session Type';
       }
       if (type === 'permit'){
           return 'Permit';
       }
       return "";
    }

    addLessonPlan()
    {
        this.product.lesson_plan = this.lessonPlan;
    }

    removeLessonPlan()
    {
        delete this.product.lesson_plan;
    }

    editProduct(formValid, form) {
        form.submitting = true;
        let product = {
            'id' : this.product.id,
            'product_name' : this.product.name,
            'product_price' : this.product.price * 100,
            'product_sku' : this.product.sku,
            'session_types' : this.product.sessiontypes,
            'course_types' : this.product.coursetypes,
            'disabled' : this.product.disabled,
            'product_description' : this.product.description,
            'categories' : this.product.categories,
            'product_sequence' : this.product.sequence,
            'purchasable' : this.product.purchasable,
            'purchase_instructions' : this.product.purchase_instructions,
            'allow_partial_payment' : this.product.allow_partial_payment,
            'can_schedule_lessons_during_checkout': this.product.can_schedule_lessons_during_checkout,
            'deposit_required' : this.product.deposit_required,
            'deposit_type' : this.product.deposit_type,
            'deposit_amount' : this.product.deposit_amount,
            'minimum_payment_amount' : this.product.minimum_payment_amount
        }
        if (this.product.lesson_plan && this.product.lesson_plan !== null) {
            product.lesson_plan = {
                id: this.product.lesson_plan.id
            }
        }
        this.ProductsModel.update(product).then(response => {
            form.submitting = false;
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.$state.go('drivecoach.admin.products', {}, {'reload' : true});
        }).catch(response => {
            form.submitting = false;
        })
    }
}

EditProductController.$inject = ['product', 'lessonPlans', 'FlashService', 'ProductsModel', '$state'];
export default EditProductController;
