let siteUrl;
siteUrl = process.env.SITE_URL;
export const Config = {
    APPURL: process.env.APP_URL,
    ENV: process.env.APP_ENV,
    HTML_FIVE_MODE: process.env.HTML_FIVE_MODE || 'off',
    SITEURL: siteUrl,
    PUSHER_KEY: process.env.PUSHER_KEY,
    HTML_FIVE_MODE: process.env.HTML_FIVE_MODE,
    VUE_APP_MAPBOX_TOKEN: process.env.VUE_APP_MAPBOX_TOKEN,
    AIRBRAKE_PROJECT_ID: process.env.AIRBRAKE_PROJECT_ID,
    AIRBRAKE_PROJECT_KEY: process.env.AIRBRAKE_PROJECT_KEY
}
