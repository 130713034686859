import verifiedImage from '../../../assets/images/unverified-students.svg'
import feesImage from '../../../assets/images/student-fees.svg'
import actionRequiredImage from '../../../assets/images/requiring-action.svg'
import openSessionsImage from '../../../assets/images/open-sessions.svg'
import {Task} from "../../classes/Task";
import cloneDeep from 'lodash/cloneDeep'
import moment from'moment'

class AdminDashboardController {

    constructor($scope, $rootScope, UserService, ReportsModel, CrmTasksModel, CalendarService, DrivesFilterService, CalendarModel, AdminTasksService, FlashService){

        this.CalendarService = CalendarService;
        this.DrivesFilterService = DrivesFilterService;
        this.CalendarModel = CalendarModel;
        this.CrmTasksModel = CrmTasksModel;
        this.AdminTasksService = AdminTasksService;
        this.$rootScope = $rootScope;
        this.FlashService = FlashService;
        this.currentUser = UserService.getCurrentUser();
        this.$scope = $scope;

        this.today = new Date;
        this.agendaDate = moment(this.today).format('YYYY-MM-DD');
        this.tasks_last_page = 0;
        this.verifiedImage = verifiedImage;
        this.feesImage = feesImage;
        this.actionRequiredImage = actionRequiredImage;
        this.openSessionsImage = openSessionsImage;
        this.user = UserService.getCurrentUser();
        this.setSchoolName();

        this.stats = {
            'students_with_fees' : 0,
            'unverified_students': 0,
            'open_sessions' : 0,
            'sessions_requiring_action' : 0
        };

        this.currentPage = 1;
        this.tasks = [];
        ReportsModel.getAdminDashboard().then(response => {
            this.stats = response.data;
        });
        
        this.AdminTasksService.loadTasks({'complete' : false}).then((response) => {
            this.handleTaskResponse(response);
        });

        $rootScope.$on('TASKS_UPDATED', () => {
            this.AdminTasksService.loadTasks({'complete' : false}).then(response => this.handleResponse(response));
        });

        let initialParams = DrivesFilterService.getFilterParameters();

        if( typeof initialParams.start_date === 'undefined' ){
            //console.log('FilterParams in Object: ' , DrivesFilterService.getFilterParameters() );
            let start_date = moment().startOf('month').format('YYYY-MM-DD')
            let end_date = moment().endOf('month').format('YYYY-MM-DD')
            initialParams = {
                'start_date': start_date,
                'end_date': end_date,
                'status': 'all',
                'statusIsnt': 'Inactive - Scheduling Conflict'
            };
        }

        /**
         * Update the calendar when the items in the service are updated
         */
        $rootScope.$broadcast('CALENDAR_LOADING');

        CalendarModel.filterAdminCalendar(initialParams)
            .then(response => {
                this.CalendarModel.setAgenda(cloneDeep(response), 5);
                let agendaData = this.CalendarModel.getAgendaData();
                if (agendaData.data.length === 0) {
                   this.agendaDate = new Date;
                }else if(agendaData.data[0].hasOwnProperty('drive_date') ){
                   this.agendaDate = agendaData.data[0].drive_date;
                }else{
                    this.agendaDate = agendaData.data[0].date;
                }
            });
    }

    setSchoolName() {
        this.schoolName = this.user.school.name;
    }

    loadMoreTasks(){
        this.AdminTasksService.loadTasks({'page' : this.currentPage + 1, complete: false }).then(response => {
            this.currentPage = response.data.current_page;
            response.data.data.forEach(task => {
                this.AdminTasksService.addTask(new Task(task));
            });
        })
    }

    handleTaskResponse(response) {
        this.tasks_last_page = response.data.last_page;
        this.currentPage = response.data.current_page;
        let tasks = response.data.data.map(task => new Task(task))             
        this.AdminTasksService.setTasks(tasks);
        this.tasks = this.AdminTasksService.getTasks();
    }
}
AdminDashboardController.$inject = ['$scope', '$rootScope', 'UserService', 'ReportsModel', 'CrmTasksModel', 'CalendarService', 'DrivesFilterService', 'CalendarModel', 'AdminTasksService', 'FlashService'];
export default AdminDashboardController;
