import moment from "moment";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { isJson } from "../../common/helpers/json";

class StudentDashboardController {
  constructor(UsersModel, UserService, ProductsModel, $sce) {
    this.loading = true;
    this.UsersModel = UsersModel;
    this.userHasBalance = false;
    this.UserService = UserService;
    this.schoolHasProducts = false;
    this.courses = [];
    this.$sce = $sce;

    ProductsModel.get({ disabled: false, purchasable: true }).then(
      (response) => {
        if (response.products.total > 0) {
          this.schoolHasProducts = true;
        }
      }
    );

    this.setup()
      .then((response) => {
        this.loading = false;
      })
      .catch((Error) => {
        this.loading = false;
      });
  }

  async setup() {
    const student = await this.getStudent();
    const pickuplocations = await this.getStudentPickupLocations();
    const nextsessiondata = await this.getNextSession();

    student.school.address = isJson(student.school.address) ? JSON.parse(student.school.address) : student.school.address;
    student.pickuplocations = pickuplocations.pickuplocations;
    this.user = student;
    this.deadline = null;
    this.hasAdminMessage = false;
    this.canAddPickupLocations = student.school.allows_custom_pickup_locations;

    if (isEmpty(nextsessiondata)) {
      this.nextDrive = {};
    } else {
      this.nextDrive = nextsessiondata.nextDrive;
      this.nextDriveDate = nextsessiondata.nextDriveDate;
      this.untilDrive = nextsessiondata.untilDrive;
    }

    const notifications = filter(student.school.policies, (policy, key) => {
      return key === "notifications";
    }).forEach((notification) => {
      if (
        notification.policymeta.hasOwnProperty("globalstudentnotification") &&
        !isEmpty(notification.policymeta.globalstudentnotification)
      ) {
        this.hasAdminMessage = true;
        this.adminMessage = this.$sce.trustAsHtml(
          notification.policymeta.globalstudentnotification
        );
      }
    });

    this.studentCanSchedule = true;
    filter(student.school.policies, (policy, key) => {
      return key === "drives";
    }).forEach((drives) => {
      if (
        drives.policymeta.hasOwnProperty("allow_students_to_schedule") &&
        !isEmpty(drives.policymeta.allow_students_to_schedule)
      ) {
        this.studentCanSchedule =
          drives.policymeta.allow_students_to_schedule == 1;
      }
    });

    if (this.user.hasOwnProperty("deadline")) {
      if (this.user.deadline > 24) {
        // process as days
        this.deadline = moment.duration(this.user.deadline, "hours").days();
        this.deadlineText = moment
          .duration(this.user.deadline, "hours")
          .humanize();
      } else if (this.user.deadline === null) {
        this.deadline = null;
      } else {
        this.deadline = moment.duration(this.user.deadline, "hours").asHours();
        this.deadlineText = moment
          .duration(this.user.deadline, "hours")
          .humanize();
      }
    }

    this.UsersModel.getStudentBalance(this.user.id).then((response) => {
      if (parseFloat(response.balance) > 0) {
        this.unpaidBalance = response.balance;
        this.userHasBalance = true;
      }
    });
    this.getUsageDetail();
  }

  userHasFees() {
    return this.user.status === "Inactive - Fee";
  }

  getUsageDetail() {
    this.UsersModel.getStudentUsageDetails(this.user.id).then((response) => {
      response.data.Overview.name = "SESSION HOURS OVERVIEW";
      this.usageoverview = response.data.Overview;
    });
    this.UsersModel.getStudentCourseDetails(this.user.id).then((response) => {
      this.courses = response.data;
    });
  }

  pastStartDate() {
    return moment(this.user.usermeta.start_date, "YYYY-MM-DD").isBefore(
      moment()
    );
  }

  getNextSession() {
    const user = this.UserService.getCurrentUser();
    return this.UsersModel.getNextStudentEntity({ id: user.id })
      .then((response) => {
        if (isEmpty(response.entity)) {
          return {};
        }
        return {
          nextDrive: response.entity,
          nextDriveDate: moment(
            response.entity.date + " " + response.entity.time
          ),
          untilDrive: moment(
            response.entity.date + " " + response.entity.time
          ).format("h:mm a"),
        };
      })
      .catch((Error) => {
        console.log(Error);
      });
  }

  getStudent() {
    const user = this.UserService.getCurrentUser();
    return this.UsersModel.getStudentByID(user.id)
      .then((response) => {
        if (!response.usermeta.hasOwnProperty("communication")) {
          response.usermeta.communication = {};
        }
        if (response.usermeta.hasOwnProperty("drive_deadline")) {
          response.usermeta.drive_deadline = moment(
            response.usermeta.drive_deadline,
            "YYYY-MM-DD"
          );
        }
        return response;
      })
      .catch((Error) => {
        console.log(Error);
      });
  }

  getStudentPickupLocations() {
    const currentUser = this.UserService.getCurrentUser();
    return this.UsersModel.getPickupLocations(currentUser.id)
      .then((response) => {
        return response;
      })
      .catch((Error) => {
        console.log(Error);
      });
  }
}

StudentDashboardController.$inject = [
  "UsersModel",
  "UserService",
  "ProductsModel",
  "$sce",
];
export default StudentDashboardController;
