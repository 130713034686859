function AdminSettingsPoliciesCancellationsController(UserService, PoliciesModel, FlashService) {

    const vm = this;
    vm.currentUser = UserService.getCurrentUser();
    vm.processPolicy = processPolicy;

    init();

    function init() {
        PoliciesModel.get().then(response => {
            vm.school = vm.currentUser.school;
            vm.policies = {};
            response.policies.forEach(policy => {
                let policyName = policy.name;
                vm.policies[policyName] = {'id': policy.id};
                let policyMeta = {};
                policy.policymeta.forEach(meta => {
                    policyMeta[meta.meta_key] = meta.meta_value;
                });
                vm.policies[policyName].policymeta = policyMeta;
            });
            vm.policies.cancellations.policymeta.fee = parseInt(vm.policies.cancellations.policymeta.fee);
            vm.policies.cancellations.policymeta.id = vm.policies.cancellations.id;
        });
    }

    function processPolicy(isValid) {
        if (isValid) {
            PoliciesModel.update(vm.policies.cancellations.policymeta).then(response => {
                FlashService.setMessage({'type': 'success', 'message': response.message});
            });
        } else {
            FlashService.setMessage({'type': 'error', 'message': 'All fields are required.'});
        }
    }

}
AdminSettingsPoliciesCancellationsController.$inject = ['UserService', 'PoliciesModel', 'FlashService'];

export default AdminSettingsPoliciesCancellationsController;