import angular from "angular";
import StudentController from "./student.controller";
import StudentNav from "./directives/student-nav/student-nav.module";
import StudentOrderForm from "./directives/order-form/order-form.module";
import FooterNav from "./directives/student-footer-nav/student-footer-nav.module";
import CompleteSetup from "./directives/complete-setup/complete-setup.module";
import Dashboard from "./dashboard/student-dashboard.module";
import Reportcard from "./report-card/student-report-card.module";
import Account from "./account/student-account.module";
import Drives from "./drives/student-drives.module";
import ProgressReport from "./student-progress-report/student-progress-report.module";
import Settings from "./settings/student-settings.module";
import Fees from "./fees/student-fees.module";
import Orders from "./orders/student-orders.module";
import PurchaseNewServices from "./purchase-new-services/purchase-new-services.module";
import Order from "./order/order.module";
import AvailableCourses from "./available-courses/available-courses.module";
import BookClass from "./book-class/book-class.module";
import Classes from "./classes/classes.module";
import StudentCourseHistory from './past-classes-and-courses/past-classes-and-courses'

import { createFromPolicyAPIResponse } from "../classes/Policies";
import { School } from "../classes/School";
import { Cashier } from "../classes/Cashier";
import { Student } from "../classes/Student";

import template from "./student.html";
function config($stateProvider, $urlRouterProvider) {
  $stateProvider.state("drivecoach.student", {
    url: "/student",
    controller: StudentController,
    resolve: {
      currentUser: getCurrentUser,
    },
    controllerAs: "vm",
    template: template,
  });
}
config.$inject = ["$stateProvider", "$urlRouterProvider"];

function getCurrentUser(
  AuthService,
  UserService,
  ENV,
  CreateOrderService,
  PoliciesModel
) {
  return AuthService.getCurrentUser()
    .then((response) => {
      UserService.setCurrentUser(response);

      const user = UserService.getCurrentUser();
      const student = new Student(user);
      
      PoliciesModel.get().then((response) => {
        const policies = createFromPolicyAPIResponse(response);
        const school = new School({
          ...response.school,
          policies: policies.toArray(),
        });
        policies.setSchool(school);
        school.setPolicies(policies);
        const cashier = new Cashier(school);
        cashier.addStudent(student);
        CreateOrderService.cashier(cashier);
      });

      return UserService.getCurrentUser();
    })
    .catch((Error) => {
      console.log(Error);
    });
}
getCurrentUser.$inject = [
  "AuthService",
  "UserService",
  "ENV",
  "CreateOrderService",
  "PoliciesModel",
];

export default angular
  .module("student", [
    StudentNav.name,
    FooterNav.name,
    CompleteSetup.name,
    Dashboard.name,
    Reportcard.name,
    Account.name,
    Drives.name,
    ProgressReport.name,
    Settings.name,
    Fees.name,
    Orders.name,
    PurchaseNewServices.name,
    StudentOrderForm.name,
    Order.name,
    AvailableCourses.name,
    BookClass.name,
    Classes.name,
    StudentCourseHistory.name,
  ])
  .config(config)
  .controller("StudentController", StudentController);
