import Dinero from 'dinero.js'

const inCents = function(dollars = 0) {
    if (dollars === null){ 
        dollars = 0;
    }
    const price = Dinero({ amount: dollars * 100, currency: 'USD' })
    return price.getAmount();
}

const inDollars = function (cents = 0) {
    if (cents === null){ 
        cents = 0;
    }
    const price = Dinero({amount: cents, currency: 'USD'})
    return price.toFormat('$0,0.00')
}

const percentageOfXInCents = function(amount, percent){
    return Dinero({ amount: amount, currency: 'USD' })
                .percentage(percent)
                .getAmount()
}

export {inCents, inDollars, percentageOfXInCents}