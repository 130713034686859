"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function FilesModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/files',
        UPDATE: APPURL + '/api/v1/files',
        CREATE: APPURL + '/api/v1/files'
    }, files;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        files = extract(result);
        return files;
    }
    model.get = function (file_id) {
        return $http.get(URLS.FETCH + '/' + file_id).then(cacheResults);
    };
    model.uploadFile = function (file_type, name, formData) {
        return $http.post(URLS.FETCH + '?file_type=' + file_type + '&name=' + name, formData, {
            headers: {
                "Content-Type": undefined
            }
        }).then(cacheResults);
    };
    model.destroy = function (id) {
        return $http.delete(URLS.FETCH + '/' + id).then(cacheResults);
    };
}
FilesModel.$inject = ['$http', 'APPURL'];
exports.default = FilesModel;
