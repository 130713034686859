import angular from 'angular'
import StudentAccountEditPermitController from './student-edit-permit.controller'
import template from './student-edit-permit.html'

function getPermit(UserService, PermitModel)
{
  return PermitModel.get(UserService.getCurrentUser().id).then(response => {
    return response;
  })
}
getPermit.$inject = ['UserService', 'PermitModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.student.account.editpermit', {
            'url' : '/edit-permit',
            'controller' : StudentAccountEditPermitController,
            'controllerAs' : 'vm',
            'template' : template,
            'resolve' : {
              permitData: getPermit
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.owner,
                    USER_ROLES.student
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'student.account.editpermit', [] )
    .config( config )
    .controller( 'StudentAccountEditPermitController', StudentAccountEditPermitController )
;
