import angular from 'angular'
import CreateProductController from './create-product.controller'
import template from './create-product.html'

function getLessonPlans( LessonPlanModel ) {
    return LessonPlanModel.get({'paging': 0}).then(response => {
        return response.lesson_plans;
    } );
}
getLessonPlans.$inject = ['LessonPlanModel'];

function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.products.create', {
            url : '/create',
            template : template,
            controller : CreateProductController,
            controllerAs : 'vm',
            resolve : {
                lessonPlans: getLessonPlans,
            },
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner,
                    USER_ROLES.staff
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.crm.products.create', [] )
    .config(config)
    .controller('CreateProductController', CreateProductController);