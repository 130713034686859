class CancelDriveModalInstanceCtrl {

    constructor($state, $uibModalInstance, drivesession, student_id, DrivesModel, FlashService, rules){
        this.$uibModalInstance = $uibModalInstance;
        this.$state = $state;
        this.DrivesModel = DrivesModel;
        this.FlashService = FlashService;

        this.drivesession = drivesession;
        this.show_last_minute_availability_alert_control = rules.showFeeWarning;
        this.send_explanation_to_student = false;
        this.cancelation_reason_explanation = null;
        this.student_id = student_id;
        this.canceling = false;
        this.cancelData = {
            'id': this.drivesession.id,
            'code': '',
            'cancelation_reason' : this.cancelation_reason_explanation,
            'student_id': this.student_id,
            'send_explanation_to_student' : this.send_explanation_to_student,
            'send_last_minute_availability_alert' : 'yes',
            'send_last_minute_cancelation_to_student' : 'yes',
            'send_last_minute_cancelation_to_instructor' : 'yes',
        };
    }

    /**
     * Cancel the drive session
     */
    cancelDriveSession() {
        this.canceling = true;
        let payload = Object.assign({}, this.cancelData)
        this.DrivesModel.cancelDriveSession(payload).then(response => {
            this.canceling = false;
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.$state.go('drivecoach.admin.calendar', {}, {'reload': true});
            this.$uibModalInstance.close();
        }).catch(Error => {
            this.canceling = false;
        });
    }

    close() {
        this.$uibModalInstance.dismiss('cancel');
    }
}
CancelDriveModalInstanceCtrl.$inject = ['$state', '$uibModalInstance', 'drivesession', 'student_id', 'DrivesModel', 'FlashService', 'rules'];

export default CancelDriveModalInstanceCtrl;
