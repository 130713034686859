import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import {Student} from '../../../../classes/Student'
import {Order} from '../../../../classes/Order'
import { isJson } from '../../../../common/helpers/json'
import { saveToDisk } from '../../../../common/helpers/saveToDisk'

class AdminStudentViewController {

    constructor($state, $stateParams, UsersModel, FlashService, $window, AdminTasksService, CreateOrderService, balance, $q, APPURL) {
        this.$q = $q;
        this.APPURL = APPURL;
        AdminTasksService.clearTasks();
        this.CreateOrderService = CreateOrderService;
        this.AdminTasksService = AdminTasksService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.currentPage = 1;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.$window = $window;
        this.student = {};
        this.hasNextSession = false;
        this.initialLoad = true;
        this.busy = false;
        this.pickuplocations = [];

        this.messengerUsers = [];

        UsersModel.getStudentUsageDetails($stateParams.userId).then(response => {
            this.usageDetails = response.data;
        });

        UsersModel.getStudentCourseDetails($stateParams.userId).then( response => {
            this.courses = response.data;
        });

        this.requirements = [];
        UsersModel.requirements($stateParams.userId).then( response => {
            this.requirements = response.requirements;
        });

        let getStudent = UsersModel.getStudentByID($stateParams.userId);
        let getPickupLocations = UsersModel.getPickupLocations($stateParams.userId);
        $q.all([getStudent, getPickupLocations]).then(responses => {
            if(responses[0].usermeta.permit) {
                responses[0].usermeta.permit.issuedate = moment(responses[0].usermeta.permit.issuedate).toDate();
                responses[0].usermeta.permit.expirationdate = moment(responses[0].usermeta.permit.expirationdate).toDate();
            }

            if (responses[0].usermeta.home_address) {
                this.home_address = responses[0].usermeta.home_address
            }

            responses[0].school.address = isJson(responses[0].school.address) ? JSON.parse(responses[0].school.address) : responses[0].school.address;
            responses[0].amount_owed = balance;
            responses[0].zoneparts = uniqBy(responses[0].zoneparts, 'zone_id');

            this.pickuplocations = responses[1].pickuplocations;

            this.student = responses[0];
            
            // Set an empty new order with this student attached to it if the admin visits the "create order" page
            this.messengerUsers.push({
                id: this.student.id,
                name: this.student.fullname,
            });
            const $student = new Student(this.student);
            const order = new Order();
            order.setStudent($student);
            this.CreateOrderService.cashier().setOrder(order);

            this.student.pickuplocations = this.pickuplocations;

            this.getNextSession()
        });

    }

    printProfile()
    {
        this.UsersModel.printStudentAccount(this.student.id).then(response => {
            this.busy = false;
            let filename = response.file.split("/")[response.file.split("/").length-1]
            saveToDisk(response.file, filename);
        }).catch(Error => {
            this.busy = false;
        })
    }
    archiveUser() {
        this.UsersModel.archiveStudent(this.student.id).then(response => {
            const confirm = this.$window.confirm("Are you sure you want to archive this student?");
            if (!confirm) {
                return;
            }
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Archived';
        });
    }

    activateUser() {
        this.UsersModel.activateStudent(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Active';
        });
    }

    deactivateUser() {
        this.UsersModel.deactivateStudent(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type': 'success',
                'message': response.message
            });
            this.student.status = 'Inactive';
        });
    }

    getNextSession() {
        this.UsersModel.getNextStudentEntity( { 'id' : this.student.id } ).then( response => {
            if( isEmpty(response.entity) ){
                this.nextSession = {};
                this.hasNextSession = false;
            } else {
                this.nextSession = {
                    'nextDrive': response.entity,
                    'nextDriveDate': moment(response.entity.date + ' ' + response.entity.time),
                    'untilDrive': moment(response.entity.date + ' ' + response.entity.time).format('h:mm a')
                }
                this.hasNextSession = true;
            }
            this.initialLoad = false;
        } )

    }

    sendWelcomeEmail() {
        const confirm = this.$window.confirm('Are you sure you want to send the welcome email? This will also reset this users password.');
        if (confirm === false) {
            return false;
        }
        this.busy = true;
        this.UsersModel.sendWelcomeEmail(this.student.id).then(response => {
            this.FlashService.setMessage({
                'type' : 'success',
                'message' : response.message
            })
            this.busy = false;
        });
    }

}
AdminStudentViewController.$inject = ['$state', '$stateParams', 'UsersModel', 'FlashService', '$window', 'AdminTasksService', 'CreateOrderService', 'balance', '$q', 'APPURL'];

export default AdminStudentViewController;
