import angular from 'angular'
import template from './admin-policies.html'
import AdminSettingsPoliciesController from './admin-policies.controller'
import DrivesPolicies from './drives/admin-settings-policies-drives.module'
import CoursesPolicies from './courses/admin-settings-policies-courses.module'
import CancelationsPolicies from './cancellations/admin-settings-policies-cancellations.module'
import PayRatiosPolicies from './pay-ratios/pay-ratios.module'
import CalendarPolicies from './calendar/admin-settings-policies-calendar.module'
import StorefrontPolicies from './storefront/admin-settings-storefront.module'
let modules = [
    DrivesPolicies.name,
    CoursesPolicies.name,
    CancelationsPolicies.name,
    PayRatiosPolicies.name,
    CalendarPolicies.name,
    StorefrontPolicies.name
];
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.settings.policies', {
            url : '/policies',
            controller : AdminSettingsPoliciesController,
            controllerAs: 'vm',
            template: template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module( 'admin.settings.policies', modules  )
    .config(config)
    .controller('AdminSettingsPoliciesController', AdminSettingsPoliciesController);
