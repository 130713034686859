import angular from 'angular'
import template from './admin-schools.html'
import AdminSchoolsController from './admin-schools.controller'
import Index from './index/admin-schools-index.module'
import View from './view/admin-school-view.module'
import Edit from './edit/admin-edit-school.module'
import Vehicles from './vehicles/admin-school-vehicles.module'
import Zones from './zones/school-zones.module'
import Billing from './billing/billing.module'
let modules = [
    Index.name,
    View.name,
    Edit.name,
    Vehicles.name,
    Zones.name,
    Billing.name
];
function config( $stateProvider, $urlRouterProvider, USER_ROLES ) {
    $stateProvider
        .state( 'drivecoach.admin.schools', {
            'url' : '/schools',
            'abstract' : true,
            'template' : template,
            data : {
                authorizedRoles : [
                    USER_ROLES.admin,
                    USER_ROLES.administrator,
                    USER_ROLES.owner
                ]
            }
        } )
    ;
}
config.$inject = ['$stateProvider', '$urlRouterProvider', 'USER_ROLES'];

export default angular.module('admin.dashboard.schools', modules).config(config)
    .controller('AdminSchoolsController', AdminSchoolsController)

;
