function PoliciesModel( $http, APPURL ) {

    let model = this,
        URLS = {
            FETCH : APPURL + '/api/v1/policies',
            UPDATE : APPURL + '/api/v1/policies',
            CREATE : APPURL + '/api/v1/policies'
        },
        policies;

    model.getByID = getByID;
    model.get = getAll;
    model.getByName = getByID;
    model.update = update;
    model.insert = insert;
    model.remove = remove;

    function extract( result ) {
        return result.data;
    }

    function cacheResults( result ) {
        policies = extract( result );
        return policies;
    }

    function getByID( id ) {
        return $http.get( URLS.FETCH + '/' + id ).then( cacheResults );
    }

    function getAll() {
        return $http.get( URLS.FETCH ).then( cacheResults );
    }

    /**
     * The shape of request is {
     *     'id' => int, // THIS IS NOT THE ID OF THE POLICYMETA OBJECT. ITS THE ID OF THE POLICY OBJECT.
     *     meta_key => meta_value,
     *     meta_key => meta_value 
     * }
     * @param request
     * @returns {*}
     */
    function update( request ) {
        let payload = Object.assign({}, request);
        delete request.id;
        return $http.put( URLS.FETCH + '/' + payload.id, request ).then( cacheResults );
    }

    function insert( request ) {
        return $http.post( URLS.FETCH, request ).then( cacheResults );
    }

    function remove( user ) {
        return $http.delete( URLS.FETCH + '/' + user.id ).then( cacheResults );
    }

}
PoliciesModel.$inject = ['$http', 'APPURL'];
export default PoliciesModel;
