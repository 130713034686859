import { range } from 'lodash';
export const Months = [
    {'name' : '1', 'value' : 1},
    {'name' : '2', 'value' : 2},
    {'name' : '3', 'value' : 3},
    {'name' : '4', 'value' : 4},
    {'name' : '5', 'value' : 5},
    {'name' : '6', 'value' : 6},
    {'name' : '7', 'value' : 7},
    {'name' : '8', 'value' : 8},
    {'name' : '9', 'value' : 9},
    {'name' : '10', 'value' : 10},
    {'name' : '11', 'value' : 11},
    {'name' : '12', 'value' : 12}
]

//this pulls in the current year plus 15 years in the future for cc forms
const min = new Date().getFullYear();
const max = min + 15;
const yearRange = range(min, max + 1);
export const Years = yearRange.map(year => {
    return { name: year, value: year }
})