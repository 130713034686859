"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function PermitModel($http, APPURL) {
    var model = this, URLS = {
        FETCH: APPURL + '/api/v1/permits',
        UPDATE: APPURL + '/api/v1/permits',
        CREATE: APPURL + '/api/v1/permits'
    }, permits;
    function extract(result) {
        return result.data;
    }
    function cacheResults(result) {
        permits = extract(result);
        return permits;
    }
    model.get = function (student_id) {
        return $http.get(URLS.FETCH + '/' + student_id).then(cacheResults);
    };
    model.update = function (student_id, permit) {
        return $http.put(URLS.FETCH + '/' + student_id, permit).then(cacheResults);
    };
    model.destroy = function (student_id) {
        return $http.delete(URLS.FETCH + '/' + student_id).then(cacheResults);
    };
}
PermitModel.$inject = ['$http', 'APPURL'];
exports.default = PermitModel;
