class AdminBillingController {

    constructor(ENV, $state, PoliciesModel, FlashService, CreateOrderService) {
        this.ENV = ENV;
        this.busy = true;
        this.loading = true;
        this.policies = {
            'billing' : {}
        };
        this.PoliciesModel = PoliciesModel;
        this.FlashService = FlashService;
        this.CreateOrderService = CreateOrderService;
        this.$cashier = CreateOrderService.cashier();

        PoliciesModel.getByID('billing').then(response => {

            this.policies = {};
            this.policies.billing = {
                'id' : 0,
                'policymeta' : {}
            };

            let policyMeta = {};
            response.policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            this.policies.billing.id = response.policy.id;
            this.policies.billing.policymeta = policyMeta;
            this.busy = false;
            this.loading = false;
        });
        this.handleTermsOfServiceChange = this.handleTermsOfServiceChange.bind(this);
        this.updateInstructorsTakingOrders = this.updateInstructorsTakingOrders.bind(this);
    }

    handleTermsOfServiceChange(data)
    {
        this.policies.billing.policymeta.terms_of_service = data.html;
    }

    updateSurcharge() {
        this.busy = true;

        let payload = {
            id: this.policies.billing.id,
            surcharge_on: this.policies.billing.policymeta.surcharge_on,
            surcharge_amount: this.policies.billing.policymeta.surcharge_amount,
            surcharge_detail: this.policies.billing.policymeta.surcharge_detail,
            terms_of_service: this.policies.billing.policymeta.terms_of_service
        }
        this.PoliciesModel.update(payload).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            if (this.$cashier.policies().policyExists('billing')){
                const billingPolicy = this.$cashier.policies().getPolicyByName('billing');
                billingPolicy.setMetaValueByKey('surcharge_on', payload.surcharge_on);
                billingPolicy.setMetaValueByKey('surcharge_amount', payload.surcharge_amount);
                billingPolicy.setMetaValueByKey('surcharge_detail', payload.surcharge_detail);
                billingPolicy.setMetaValueByKey('terms_of_service', payload.terms_of_service);
                this.CreateOrderService.$cashier.policies().setPolicy(billingPolicy);
                this.CreateOrderService.$cashier.setupPolicies();
            }
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });

    }

    updateInstructorsTakingOrders() {
        this.busy = true;

        let payload = {
            id: this.policies.billing.id,
            instructor_can_create_orders: this.policies.billing.policymeta.instructor_can_create_orders,
        }
        this.PoliciesModel.update(payload).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            if (this.$cashier.policies().policyExists('billing')){
                const billingPolicy = this.$cashier.policies().getPolicyByName('billing');
                billingPolicy.setMetaValueByKey('surcharge_on', payload.instructor_can_create_orders);
                this.CreateOrderService.$cashier.policies().setPolicy(billingPolicy);
                this.CreateOrderService.$cashier.setupPolicies();
            }
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });

    }

    processPolicy() {
        this.busy = true;

        let request = this.policies.billing.policymeta;
        request.id = this.policies.billing.id;

        this.PoliciesModel.update(request).then(response => {
            this.FlashService.setMessage({'type': 'success', 'message': response.message});
            this.busy = false;
        }).catch(Error => {
            this.busy = false;
        });

    }

}
AdminBillingController.$inject = ['ENV','$state', 'PoliciesModel', 'FlashService', 'CreateOrderService'];

export default AdminBillingController;