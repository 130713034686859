class AdminSchoolBillingController {

    constructor($state, $stateParams, UserService, SchoolsModel, FlashService, TIMEZONES, UsageReportModel){
        Object.assign(this, [...$state, $stateParams, SchoolsModel, FlashService, TIMEZONES, UsageReportModel]);
        this.plan = {
            'name' : ''
        };
        let date = new Date();
        this.thisMonth = `${date.getMonth() + 1} / ${date.getFullYear()}`;
        this.newStudentsThisMonth = 0;
        this.charges = [];
        this.SchoolsModel = SchoolsModel;
        this.UsageReportModel = UsageReportModel;
        this.currentUser = UserService.getCurrentUser();
        this.getPlan();
        this.getCharges();
        this.getNewStudentsThisMonth()
    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getPlanLabel(plan){
        return '$' + plan.base_price / 100 + '/mo';
    }

    update(){

    }

    getNewStudentsThisMonth(){
        this.UsageReportModel.get({'period': 'monthly', 'month': new Date().getMonth() + 1}).then( response => {
            this.newStudentsThisMonth = response.data.students;
        });
    }

    getPlan(){
        this.SchoolsModel.getSchoolPlan(this.currentUser.school_id).then(response => {
            this.plan = response.plan;
        })
    }

    getCharges(){
        this.SchoolsModel.getSchoolCharges(this.currentUser.school_id).then(response => {
            this.charges = response.charges;
        })
    }

    cancelSubscription(){
        this.SchoolsModel.cancelSubscription(this.currentUser.school_id).then(response => {
            this.plan = response.plan;
        })
    }

}

AdminSchoolBillingController.$inject = ['$state', '$stateParams', 'UserService', 'SchoolsModel', 'FlashService', 'TIMEZONES', 'UsageReportModel'];
export default AdminSchoolBillingController;