let siteUrl;
siteUrl = process.env.SITE_URL;
export const Config = angular.module("constants.config", [])
.constant("APPURL", process.env.APP_URL)
.constant("ENV", process.env.APP_ENV)
.constant("HTML_FIVE_MODE", process.env.HTML_FIVE_MODE || 'off')
.constant("SITEURL", siteUrl)
.constant("HTML_FIVE_MODE", process.env.HTML_FIVE_MODE || 'off')
.constant('AIRBRAKE_PROJECT_ID', process.env.AIRBRAKE_PROJECT_ID)
.constant('AIRBRAKE_PROJECT_KEY', process.env.AIRBRAKE_PROJECT_KEY)
.constant("PUSHER_KEY", process.env.PUSHER_KEY);
