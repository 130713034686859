import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
class StudentAccountEditController {

    constructor($state, UserService, UsersModel, FlashService){
        this.$state = $state;
        this.UserService = UserService;
        this.UsersModel = UsersModel;
        this.FlashService = FlashService;
        this.user = UserService.getCurrentUser();
        this.submitted = false;
        this.editPassword = false;

        UsersModel.getStudentByID( this.user.id ).then( response => {
            const user = cloneDeep(response);
            user.usermeta.drive_deadline = moment( response.usermeta.drive_deadline , 'YYYY-MM-DD' ).format('MM/DD/YYYY');
            if(user.usermeta.hasOwnProperty('permit')) {
                user.usermeta.permit.issuedate = moment(response.usermeta.permit.issuedate, 'YYYY-MM-DD').format('MM/DD/YYYY');
                user.usermeta.permit.expirationdate = moment(response.usermeta.permit.expirationdate, 'YYYY-MM-DD').format('MM/DD/YYYY');
            }
            user.usermeta.start_date = moment( response.usermeta.start_date , 'YYYY-MM-DD').format('MM/DD/YYYY');
            user.usermeta.dob = moment( response.usermeta.dob, 'YYYY-MM-DD' ).format( 'MM/DD/YYYY' );
            this.user = user;
        } );
    }

    getStudentAge() {
        if( ! this.user.hasOwnProperty('usermeta') || typeof this.user.usermeta.dob === 'undefined' ){
            return 100;
        }
        let dob = moment(this.user.usermeta.dob, 'MMDDYYYY' ).toDate();
        return moment( new Date() ).diff( moment( dob ), 'years' );
    }

     submit( isValid ) {
        this.submitted = true;
        if ( isValid ) {
            const postData = cloneDeep(this.user);
            postData.usermeta.formatted_drive_deadline = moment( this.user.usermeta.drive_deadline, 'MM/DD/YYYY' ).format( 'YYYY-MM-DD' );
            if(this.user.usermeta.hasOwnProperty('permit')) {
                postData.usermeta.formatted_permit_issue_date = moment(this.user.usermeta.permit.issuedate, 'MM/DD/YYYY').format('YYYY-MM-DD');
                postData.usermeta.formatted_permit_expiration_date = moment(this.user.usermeta.permit.expirationdate, 'MM/DD/YYYY').format('YYYY-MM-DD');
            }
            postData.usermeta.start_date = moment( this.user.usermeta.start_date, 'MM/DD/YYYY' ).format( 'YYYY-MM-DD' );
            postData.usermeta.dob = moment( this.user.usermeta.dob, 'MM/DD/YYYY' ).format( 'YYYY-MM-DD' );

            postData.usermeta.receives_last_minute_text_reminders = 'N';
            if(postData.usermeta.hasOwnProperty('guardian')) {
                if (!postData.usermeta.guardian.hasOwnProperty('reminders')) {
                    postData.usermeta.guardian.reminders = {
                        'receives_last_minute_text_reminders' : 'N'
                    }
                } else {
                    postData.usermeta.guardian.reminders.receives_last_minute_text_reminders = 'N';
                }
            }

            this.UsersModel.updateStudent( postData ).then( response => {
                this.FlashService.setMessage( {
                    'type' : 'success',
                    'message' : response.message
                } );
                this.submitted = false;
                this.$state.go( 'drivecoach.student.account' );
            } ).catch(response => {
                this.submitted = false;
            });
        } else {
            this.FlashService.setMessage( { 'type' : 'error', 'message' : 'All fields are required.' } );
        }
    }
}
StudentAccountEditController.$inject = [ '$state', 'UserService', 'UsersModel', 'FlashService'];
export default StudentAccountEditController;