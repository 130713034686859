class dsProductFormController {
    constructor(UserService){
      this.user = UserService.getCurrentUser();
      this.school = this.user.school;
      this.handlePurchaseInstructionsChange = this.handlePurchaseInstructionsChange.bind(this)
      this.handleProductDescriptionChange = this.handleProductDescriptionChange.bind(this)
    }
  
    handlePurchaseInstructionsChange(data) {
      this.product['purchase_instructions'] = data.html;
    }

    handleProductDescriptionChange(data){
      this.product['description'] = data.html;
    }
}
dsProductFormController.$inject = ['UserService'];
export default dsProductFormController;