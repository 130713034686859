import headerImage from '../../assets/images/logo-no-icon.svg'
import { createFromPolicyAPIResponse } from "../classes/Policies"
import { School } from '../classes/School'
import { Order } from '../classes/Order'
import { Cashier } from '../classes/Cashier'

class AdminController {

    constructor($rootScope, $state, AUTH_EVENTS, UserService, ToasterService, CreateOrderService, PoliciesModel, ENV, currentUser, VueStoreFactory){
        this.ENV = ENV;
        this.loading = true;
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.AUTH_EVENTS = AUTH_EVENTS;
        this.UserService = UserService;
        this.VueStoreFactory = VueStoreFactory;
        
        // this.PusherService = PusherService;
        this.currentUser = UserService.getCurrentUser();
        this.ToasterService = ToasterService;
        this.CreateOrderService = CreateOrderService;
        this.PoliciesModel = PoliciesModel;
        // PusherService.subscribe();

        this.adminHeaderImage = headerImage;
        this.setSchoolName();
        this.setSchoolMode();
        this.setUserName();
        this.configureCashier();
        
        this.setSchoolName = this.setSchoolName.bind(this);
        this.setSchoolMode = this.setSchoolMode.bind(this);

        this.$rootScope.$on(AUTH_EVENTS.loginSuccess, () => {
            this.setSchoolName();            
        });

        this.state = {
            search : {
                selectedUser: null,
                studentSchoolOverride: false,
                roles: ['student', 'instructor'],
                handleSelectUser: (user) => {
                    if (user === null) {
                        return;
                    }
                    if (user.type === 'student') {
                        // go to student
                        this.$state.go('drivecoach.admin.students.view', {'userId':user.id});
                    }
                    if (user.type === 'instructor') {
                        // go to instructor
                        this.$state.go('drivecoach.admin.instructors.view', { 'instructorId': user.id })
                    }
                }
            }
        }
    }


    configureCashier(){
       
        this.PoliciesModel.get().then(response => {
            this.VueStoreFactory.dispatch('SET_POLICIES', response);

            const policies = createFromPolicyAPIResponse(response);
            const school = new School({...response.school, policies: policies.toArray()});
            policies.setSchool(school);
            school.setPolicies(policies);
            const cashier = new Cashier(school, new Order());
            this.CreateOrderService.cashier(cashier);
            this.loading = false;
        })
        
    }


    // if user is logged in, send them to the dashbaord
    // otherwise send them to the login page

    setSchoolName() {
        this.schoolName = this.currentUser.school.name;
    }
  
  
    setSchoolMode() {
      this.schoolMode = this.currentUser.school.status
    }

    // for now just show the dashboard
    //$state.go('drivecoach.admin.dashboard');
    setUserName() {
        this.user = this.currentUser;
    }

}
AdminController.$inject = ['$rootScope', '$state', 'AUTH_EVENTS', 'UserService', 'ToasterService', 'CreateOrderService', 'PoliciesModel', 'ENV', 'currentUser', 'VueStoreFactory'];
export default AdminController;
