import {Months} from "../../common/constants/months_and_years";
import {Years} from "../../common/constants/months_and_years";
import cloneDeep from 'lodash/cloneDeep'
import {States} from "../../common/constants/states";
import { inDollars } from "../../../DriveScout/Billing/Teller";

class StudentFeesController {

    constructor($stateParams, UsersModel, FlashService, UserService, PoliciesModel, PayPalService){
        this.inDollars = inDollars.bind(this);
        this.$stateParams = $stateParams;
        this.student = UserService.getCurrentUser();
        this.UsersModel = UsersModel;
        this.PayPalService = PayPalService;
        this.FlashService = FlashService;
        this.payment = {}
        this.fees = [];
        this.addresses = [];
        this.fee = null;
        this.months = Months;
        this.years = Years;
        this.states = States;
        this.viewing = 'fees';

        this.UsersModel.getPickupLocations(this.student.id).then(response => {
            this.addresses = response.pickuplocations.map(location => {
                return location.address;
            });
        })

        this.policies = {
            'billing' : {
                'policymeta': {
                }
            }
        }
        this.surchargeBilledSeparately = false;
        this.surchargeDetail = '';
        this.surchargeAmountBilled = 0;
        this.surchargeAmount = 0;
        this.paidToday = 0;

        PoliciesModel.getByID('billing').then(response => {
            let policyMeta = {};
            response.policy.policymeta.forEach(meta => {
                policyMeta[meta.meta_key] = meta.meta_value;
            });
            this.policies.billing.policymeta = policyMeta;
            this.policies.billing.policymeta.id = response.policy.id;
            this.gateway = this.policies.billing.policymeta.billing_gateway;
            this.gateway_config = {
                paypal_rest_api_client_id : this.policies.billing.policymeta.paypal_rest_api_client_id
            }

            if (policyMeta.hasOwnProperty('surcharge_amount')) {
                this.surchargeBilledSeparately = parseFloat(policyMeta.surcharge_amount) > 0;
                this.surchargeAmount = parseFloat(policyMeta.surcharge_amount);
            }

            if (policyMeta.hasOwnProperty('surcharge_detail')) {
                this.surchargeDetail = policyMeta.surcharge_detail;
            }

            // this.loading = false;
            // this.submitting = false;
        });

        this.getFees();
        
    }

    getFees(){
        this.submitted = true;
        this.UsersModel.getStudentFees(this.student.id).then(response => {
            this.fees = response.fees;
            this.viewing = 'fees';
            this.submitted = false;
        });
    }

    addBillingAddress(index, address){
        if( ! this.payment.hasOwnProperty('card') ){
            this.payment.card = {}
        }
        this.payment.card.billing_address = address;
    }

    selectFee(feeId, index){
        this.fee = this.fees[index];
        this.payment = {
            'payment_method' : 'card',
        }
    }

    checkSurchargeRequired() {
        return this.surchargeBilledSeparately && this.payment.payment_method === 'card';
    }

    calculateTotal() {
        if (this.surchargeBilledSeparately && this.payment.payment_method === 'card') {
            let surchargeAsDecimal = this.surchargeAmount / 100;

            let surcharge = (this.payment.payment_amount * surchargeAsDecimal) / 100;
            this.surchargeAmountBilled = surcharge.toFixed(2)
            let total = this.payment.payment_amount + (surcharge.toFixed(2) * 100);
            let totalInDollars = total / 100;
            this.paidToday = totalInDollars.toFixed(2);
            if (this.gateway === 'paypal') {
                this.PayPalService.setPurchaseUnits([{
                    amount: {
                        value: this.paidToday,
                        description: 'Payment for Drive Scout Fee #' + this.fee.id,
                        currency_code: 'USD',
                    },
                }]);
            }
            return total;
        }

        let orderTotalInCents = this.fee.amount;

        this.fee.amount = orderTotalInCents;
        return orderTotalInCents;
    }

    submitPayment(){
        this.submitted = true;
        let payment = cloneDeep(this.payment);
        this.UsersModel.submitPayment(this.student.id, this.fee.id, {'payment': payment}).then(response => {
           this.submitted = false;
           this.FlashService.setMessage({
               'type' : 'success',
               'message' : response.message
           })
           // replace fee
           this.fees.forEach((_fee, index) => {
               if( _fee.id === response.fee.id ){
                   this.fees[index] = response.fee;
               }
           })
            this.payment = {};
            this.fee = null;
        }).catch(response => {
            this.submitted = false;
        })
    }

}
StudentFeesController.$inject = ['$stateParams', 'UsersModel', 'FlashService', 'UserService', 'PoliciesModel', 'PayPalService'];
export default StudentFeesController;